"use client";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UserContext } from "./context/user-context";
import { DealershipContext } from "./context/dealership-context";
import Cookies from "js-cookie";
import LogoText from "@/app/_components/logos/LogoTest";
import { useRouter } from "next/navigation";
import {
  defaultMobileNavPages,
  deliveryCalendarNew,
  salesLogNew,
  salesLogSalesPersonNew,
} from "../types";

import { useClerk } from "@clerk/nextjs";
import Link from "next/link";
import styles from "../_components/styles/animation.module.css";

interface NavMobileProps {
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}
export const NavMenuMobile = ({ setMenuOpen }: NavMobileProps) => {
  const { user, userGroups, getPrimaryUserGroup, mobileTheme } =
    useContext(UserContext);
  const { dealerships, currentDealership, setCurrentDealership, hasFeature } =
    useContext(DealershipContext);

  const { signOut } = useClerk();
  const [userPermissions, setUserPermissions] = useState<string[]>([]);
  const [isAdminOnCurrentStore, setIsAdminOnCurrentStore] =
    useState<boolean>(false);
  const [menuScale, setMenuScale] = useState({ x: 1, y: 1 });
  const router = useRouter();

  const changeDealership = (dealershipId: string) => {
    const dealership = dealerships.find(
      (dealership) => dealership.id === dealershipId,
    );

    if (!dealership) {
      return;
    }

    Cookies.set("dealershipId", dealershipId);
    setCurrentDealership(dealership);
    location.reload();
  };

  useEffect(() => {
    if (!user || !currentDealership) return;

    const found =
      userGroups &&
      userGroups.find((group) => group.dealershipId === currentDealership._id);
    if (typeof found === "undefined") return;

    setIsAdminOnCurrentStore(found.name === "Admin");
  }, [user, userGroups]);

  const primaryUserGroup = useMemo(() => {
    if (!currentDealership) return;

    const primary = getPrimaryUserGroup(
      currentDealership._id ?? currentDealership.id,
    );
    return primary;
  }, [currentDealership, userGroups, getPrimaryUserGroup]);

  useEffect(() => {
    if (!currentDealership || !user) {
      return;
    }

    if (user.permissions[currentDealership.id]) {
      setUserPermissions(user.permissions[currentDealership.id]);
    }

    if (!primaryUserGroup) {
      return;
    }

    setUserPermissions(primaryUserGroup.permissions);
  }, [primaryUserGroup, user, currentDealership]);

  const mobileNavPages = useMemo(() => {
    if (!currentDealership) return [];

    if (!currentDealership.features) {
      return Object.values(defaultMobileNavPages);
    }

    const tempPages = { ...defaultMobileNavPages };

    if (hasFeature("SALES_LOG_V2")) {
      tempPages["sales-log"] = salesLogNew;
      tempPages["sp-saleslog"] = salesLogSalesPersonNew;
    }

    if (hasFeature("DELIVERY_CALENDAR_V2")) {
      tempPages["delivery-calendar"] = deliveryCalendarNew;
    }

    // Return the remaining pages as an array
    return Object.values(tempPages);
  }, [currentDealership, hasFeature]);

  useEffect(() => {
    let touchStartY = 0;
    let touchEndY = 0;

    const handleTouchStart = (e) => {
      touchStartY = e.touches[0].clientY;
      touchEndY = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
      touchEndY = e.touches[0].clientY;
      const swipeDistance = touchStartY - touchEndY;
      if (swipeDistance > 0) {
        const newScaleY = 1 - swipeDistance * 0.001;
        const newScaleX = 1 - swipeDistance * 0.0005; // Adjust this value to control the x-axis scaling speed
        setMenuScale({
          x: newScaleX > 0 ? newScaleX : 0,
          y: newScaleY > 0 ? newScaleY : 0,
        });
      }
    };

    const handleTouchEnd = () => {
      const swipeDistance = touchStartY - touchEndY;
      if (swipeDistance > 100) {
        setMenuScale({ x: 0.4, y: 0 });
        setTimeout(() => {
          setMenuOpen(false);
        }, 150);
      } else {
        setMenuScale({ x: 1, y: 1 });
      }
    };

    const menuElement = document.querySelector("#nav-menu-mobile");
    if (menuElement) {
      menuElement.addEventListener("touchstart", handleTouchStart);
      menuElement.addEventListener("touchmove", handleTouchMove);
      menuElement.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (menuElement) {
        menuElement.removeEventListener("touchstart", handleTouchStart);
        menuElement.removeEventListener("touchmove", handleTouchMove);
        menuElement.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [setMenuOpen]);

  return (
    <div
      id="nav-menu-mobile"
      className={` ${styles.menuFade} fixed w-full m-auto  flex-col shadow-2xl  flex  items-center shrink-0 h-[93vh] z-[1000]`}
    >
      <nav
        className={`${styles.menuFade} fixed w-[90%] m-auto mt-6 rounded-lg  flex-col shadow-2xl flex justify-center shrink-0 h-[83svh] z-[1000]`}
        style={{
          transform: `scale(${menuScale.x}, ${menuScale.y})`,
          transition: "transform 0.5s",
          backgroundColor: mobileTheme.BACKGROUND,
          transformOrigin: "top",
        }}
      >
        <div className="flex flex-col h-full justify-between">
          <div className="pt-4">
            <ul className="flex flex-col pl-6 gap-8 w-full">
              <div className=" flex justify-between items-between w-[95%]">
                <div className="w-8"></div>
                <LogoText width="200px" />
                <button
                  className="w-8 h-8 font-bold text-lg"
                  style={{ color: mobileTheme.FONT1 }}
                  onClick={() => {
                    setMenuOpen(false);
                  }}
                >
                  X
                </button>
              </div>

              {userPermissions &&
                mobileNavPages
                  .filter(
                    (page) =>
                      userPermissions.includes(page.permission!) ||
                      !page.permission,
                  )
                  .map((page) => {
                    return (
                      <Link
                        key={page.name}
                        href={page.path}
                        className="flex flex-row justify-start items-center p-2  text-sm hover:text-black bg:gray-200 leading-6 font-medium rounded-lg w-[90%] focus:bg-gray-500 focus:scale-105 ease-in"
                        style={{ color: mobileTheme.FONT1 }}
                      >
                        <i className={`fal ${page.icon} fa-fw mr-2`}></i>{" "}
                        {page.name}
                      </Link>
                    );
                  })}
              {dealerships && dealerships.length > 1 ? (
                <li className="group  relative dropdown text-[#6e84a3] text-sm hover:text-black leading-6 font-medium">
                  <a className="nav-link" href="#">
                    <i className={`fal fa-home fa-fw mr-2`}></i> My Dealerships
                  </a>

                  <div className="group-hover:block dropdown-menu hidden h-auto">
                    <ul className="top-0 bg-white shadow w-full">
                      {currentDealership &&
                        dealerships.map((dealership) => {
                          return (
                            <li
                              onClick={() => {
                                changeDealership(dealership.id);
                              }}
                              key={dealership.id}
                              className="cursor-pointer py-3 pl-2 flex flex-row items-center "
                            >
                              {" "}
                              {currentDealership!.id === dealership.id ? (
                                <i
                                  style={{
                                    color: "#42f58d",
                                  }}
                                  className="fas fa-check-circle font-size-sm ml-0 pr-2"
                                >
                                  {" "}
                                </i>
                              ) : (
                                <></>
                              )}{" "}
                              <a
                                role="button"
                                className="block text-sm text-[#6e84a3] "
                              >
                                {dealership.name}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>

          <div className="flex flex-col text-center pb-4">
            <div className="pb-4 pl-6 pr-6 pt-6 text-gray-500 text-sm border-t border-gray-400">
              {user && user.name}
            </div>
            {dealerships && dealerships.length > 1 && isAdminOnCurrentStore ? (
              <button
                type="button"
                onClick={() => {
                  router.push("/multi-store");
                }}
                className="text-[#43d662] hover:text-white border border-[#43d662] p-2 hover:bg-[#43d662] focus:ring-4 focus:outline-none focus:ring-[#43d662] font-medium rounded-lg text-sm text-center ml-4 mr-4 mb-2"
              >
                Executive View
              </button>
            ) : (
              <></>
            )}
            <button
              onClick={async () => {
                await signOut({ redirectUrl: "/v2/sign-in" });
                Cookies.remove("dealershipId", { path: "/" });
              }}
              type="button"
              className="text-red-600 mb-2 hover:text-white border border-red-600 p-2 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm text-center  dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900 ml-4 mr-4"
            >
              Sign out
            </button>
          </div>
        </div>
        <div className="w-full h-8 flex justify-center items-center">
          <div className="w-32 h-1 bg-gray-400 rounded-full"></div>
        </div>
      </nav>
    </div>
  );
};

export default NavMenuMobile;
